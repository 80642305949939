import { apiUsdkBatchGetAgreementsV2, apiUsdkSwitchConfGetSwitchList, apiUsdkSwitchConfSaveSwitch } from '@/api/usdk';
import { useAppStore } from '@/store/modules/app';
import { UseTable } from '@ultra/share/components/UTable/useTable';
import ultraUploadFile from '@ultra/share/utils/ultraUpload.js';
export const UseSwitchCommon = () => {
  const filter = reactive({
    cid: ''
  });
  const gameInfo = computed(() => useAppStore().gameInfo);
  const confirmLoading = ref(false);
  const getTableData = cb => {
    tableLoading.value = true;
    apiUsdkSwitchConfGetSwitchList({
      cid: filter.cid,
      gid: gameInfo.value && gameInfo.value.Id
    }).then(res => {
      if (res.code === 0) {
        tableData.value = res.data;
      }
      typeof cb === 'function' && cb();
      tableLoading.value = false;
    });
  };
  // 保存禁用配置
  const saveSwitch = (params, cb) => {
    confirmLoading.value = true;
    apiUsdkSwitchConfSaveSwitch(params).then(res => {
      if (res.code === 0) {
        getTableData();
        if (cb) {
          cb();
        }
      }
      confirmLoading.value = false;
    });
  };
  const switchType = (val, type) => {
    // 新增 登录 支付
    let arr = [0, 1, 2];
    let close = {
      type: 'info',
      text: '已关闭',
      open: 0
    };
    let check = {
      type: 'success',
      text: '已开启',
      open: 1
    };
    if (arr.includes(type)) {
      if (val === null || val === '' || val == 1) {
        return check;
      } else {
        return close;
      }
    } else if (type == 6) {
      // 签名校验是反着的
      if (val == 1) {
        return {
          type: 'info',
          text: '已关闭',
          open: 1
        };
      } else {
        return {
          type: 'success',
          text: '已开启',
          open: 0
        };
      }
    } else {
      if (val == 1) {
        return check;
      } else {
        return close;
      }
    }
  };
  const {
    tableData,
    tableLoading,
    current,
    pageSize,
    total,
    handleSizeChange,
    handleCurrentChange
  } = UseTable(getTableData);
  watch(() => gameInfo.value.Id, val => {
    if (val) {
      getTableData();
    }
  }, {
    immediate: true
  });
  return {
    filter,
    tableData,
    tableLoading,
    current,
    pageSize,
    total,
    handleSizeChange,
    handleCurrentChange,
    saveSwitch,
    confirmLoading,
    getTableData,
    gameInfo,
    gid: gameInfo && gameInfo.value.Id,
    switchType
  };
};
export const UseDefaultAgreement = () => {
  const keyDict = ['priAgrName', 'priAgrUrl', 'userAgrName', 'userAgrUrl', 'childAgrName', 'childAgrUrl', 'sdkAgrName', 'sdkAgrUrl', 'version', 'conditions', 'swPermission', 'woffAgrName', 'woffAgrUrl'];
  // const DEFAULT_CONF = {
  // userAgrName: 'UltraSDK用户协议',
  // userAgrUrl: 'https://www.yingxiong.com/p/agreement_global.html',
  // priAgrName: 'UltraSDK个人信息保护政策',
  // priAgrUrl: 'https://www.yingxiong.com/p/per_adu.html',
  // childAgrName: 'UltraSDK儿童个人信息保护政策',
  // childAgrUrl: 'https://www.yingxiong.com/p/per_child.html'
  // };
  return {
    // DEFAULT_CONF,
    keyDict
  };
};
export const UseUploadText = () => {
  const uploadText = (cid, text, productID) => {
    ultraUploadFile({
      params: {
        gid: productID,
        cid: cid == 6 ? 0 : cid,
        text
      },
      type: 3
    }).then(res => {
      console.log(res.url);
    });
  };
  const batchUploadCdnText = (cids, gid, productID) => {
    apiUsdkBatchGetAgreementsV2({
      cids: cids.join(','),
      gid
    }).then(res => {
      if (res.code === 0) {
        res.data.forEach(item => {
          uploadText(item.cid, JSON.stringify(item), productID);
        });
      }
    });
  };
  return {
    uploadText,
    batchUploadCdnText
  };
};