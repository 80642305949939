export const UseTable = getTableData => {
  const tableData = ref([]);
  const tableLoading = ref(false);
  const total = ref(0);
  const current = ref(1);
  const pageSize = ref(10);
  const handleSearch = () => {
    current.value = 1;
    getTableData();
  };
  const handleSizeChange = val => {
    pageSize.value = val;
    getTableData();
  };
  const handleCurrentChange = val => {
    current.value = val;
    getTableData();
  };
  return {
    tableData,
    tableLoading,
    total,
    current,
    pageSize,
    handleSearch,
    handleSizeChange,
    handleCurrentChange
  };
};